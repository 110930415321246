<template>
  <!-- 咨询人数 -->
  <my-dialog title="设置咨询人数" @cancel="close" :visible="visible">
 
  </my-dialog>
</template>

<script>
  import MyDialog from "../../../../components/MyDialog";
  import {getInquiry, setCurrPatient} from "../../../../api/DoctorApi";

  export default {
    name: 'PayImgDialog',
    components: {MyDialog},
    data() {
      return {
        visible: false,
        form: {}
      }
    },

    methods: {

      /**
       * 展示信息
       */
      show() {
        this.visible = true; 
      },

      /**
       * 关闭弹窗
       */
      close() {
        this.visible = false;
        this.form = {}
      },
 
    }
  }
</script>

<style scoped lang="scss">

  .el-form {
    margin-top: 40px;
    padding: 0 20px;
    text-align: center;

    .el-button {
      min-width: 120px;
      margin-left: 10px;
    }

    .tips {
      font-size: 12px;
      color: $red;
    }
  }

</style>
