<template>
  <div class="container" :style="{width: width,height: height,boxSizing: 'border-box'}">
    <div class="avatar">
 
	  
	  <img style="width: 100%;height: 154px"  :src="info.pic" > 
	  
	  
    </div>
    <div class="info">
      <div class="info_name">{{info.name}}</div>
      <div class="info_intro">
        <p>年龄：{{info.age}}</p>
        <p>学历：{{info.education}}</p>
        <p>专业：{{info.major}}</p>
      </div>
      <div class="info_more">
        <el-button @click="handleClick">查看详情</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SideCard",
    props: {
      info: {
        type: Object,
        default: function () {
          return {
            name: '弘淑',
            major: '心脑专业',
            education: '医学博士',
            age: '25岁',
            competent: '国家十大青年代表，2020济南之光',
            pic: require('@/assets/avatar/弘淑.jpg')
          }
        }
      },
      width: {
        type: String,
        default: '195px'
      },
      height: {
        type: String,
        default: '320px'
      }
    },

    methods:{

      /**
       * 当点击查看详情时的按钮，执行回调
       */
      handleClick(){
        this.$emit("btn-click");
      }
    }
  }
</script>

<style scoped lang="scss">
  .container{
    padding: 15px;
    border-radius: 2px;
    background-color: #fff;
    &.active{
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    &:hover{
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .avatar{
      width: 162px;
      height: 154px;
    }
    .info{
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      line-height: 18px;
      .info_name{
        font-size: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        color: #363636;
        font-weight: bold;
        margin: 16px 0 8px;
      }
      .info_intro{
        margin-bottom: 6px;
        letter-spacing: 0;
      }
      .info_more {
        .el-button{
          width: 130px;
          height: 30px;
          background-color: $red!important;
          border-color: $red!important;
        }
      }
    }

  }

</style>
