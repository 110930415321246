export default {
  all: [
    {
      name: '弘淑',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/弘淑.jpg')
    },
    {
      name: '由美',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/由美.jpg')
    },
    {
      name: '习春',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/习春.jpg')
    },
    {
      name: '格凤',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/格凤.jpg')
    },
    {
      name: '方毓固',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/方毓固.jpg')
    },
    {
      name: '上官芝',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/上官芝.jpg')
    },
    {
      name: '禄武',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/禄武.jpg')
    },
    {
      name: '巨蕊',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/巨蕊.jpg')
    },
    {
      name: '鲍敬',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/鲍敬.jpg')
    },
    {
      name: '龚福振',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/龚福振.jpg')
    },
    {
      name: '聂纨',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/聂纨.jpg')
    },
    {
      name: '谯悦',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/谯悦.jpg')
    },
    {
      name: '廖亮秀',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/廖亮秀.jpg')
    },
    {
      name: '蒋瑞姬',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/蒋瑞姬.jpg')
    },
    {
      name: '仇霞',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/仇霞.jpg')
    },
    {
      name: '权青',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/权青.jpg')
    },
    {
      name: '腾寒仪',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/腾寒仪.jpg')
    },
    {
      name: '嵇巧士',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/嵇巧士.jpg')
    },
    {
      name: '赛勇秋',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/赛勇秋.jpg')
    },
    {
      name: '通黛',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/通黛.jpg')
    }
  ],
  top: [
    {
      name: '权青',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/权青.jpg')
    },
    {
      name: '腾寒仪',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/腾寒仪.jpg')
    },
    {
      name: '嵇巧士',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/嵇巧士.jpg')
    },
    {
      name: '赛勇秋',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/赛勇秋.jpg')
    },
    {
      name: '通黛',
      major: '心脑专业',
      education: '医学博士',
      age: '25岁',
      competent: '国家十大青年代表，2020济南之光',
      pic: require('@/assets/avatar/通黛.jpg')
    }
  ]
}
